import { sendEmailVerification } from "firebase/auth";

export const doSendEmailVerification = auth =>
  sendEmailVerification(auth.currentUser, {
    url: `${process.env.GATSBY_SITE_URL}login`
  });

export const userVerified = user => {
  const resp = [];
  resp.email = true;
  resp.terms = true;
  resp.verified = true;
  resp.complete = true;

  if (typeof user === "undefined") {
    resp.complete = false;
    return resp;
  }

  if (user.emailVerified !== true) {
    resp.email = false;
    resp.complete = false;
  }

  if (user.terms) {
    if (user.terms.agreed !== true) {
      resp.terms = false;
      resp.complete = false;
    }
  }

  if (user.verified.status !== "complete") {
    resp.verified = false;
    resp.complete = false;
  }

  return resp;
};

export const formatTimestamp = timestamp => {
  const a = new Date(timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();

  return `${date} ${month} ${year}`;
};

export const statusList = idx => {
  const status = [
    "Awaiting payment",
    "Payment recieved",
    "Work Commenced",
    "Work completed",
    "Complete",
    "Dispute"
  ];

  if (typeof idx !== "undefined") {
    return status[idx];
  }

  return status;
};

const handleFileUploadOnFirebaseStorage = async (
  bucketName,
  file,
  fetchStorage
) => {
  // 1. If no file, return
  if (file === "") return "";

  // 2. Put the file into bucketName
  const uploadTask = await fetchStorage
    .ref(`/${bucketName}/${file.name}`)
    .put(file);

  // 3. Get download URL and return it as
  return uploadTask.ref.getDownloadURL().then(fileURL => fileURL);
};

export const uploadImage = async (inputFiles, resp, fetchStorage) => {
  // 1. If no file, return
  if (inputFiles.length === 0) return [];

  // 2. Create an array to store all download URLs
  const fileUrls = [];

  // 3. Loop over all the files
  for (let i = 0; i < inputFiles.length; i += 1) {
    // 3A. Get a file to upload
    const file = inputFiles[i];

    // 3B. handleFileUploadOnFirebaseStorage function is in above section
    /* eslint-disable no-await-in-loop */
    const downloadFileResponse = await handleFileUploadOnFirebaseStorage(
      `/${resp.id}/${file.name}`,
      file,
      fetchStorage
    );

    // 3C. Push the download url to URLs array
    fileUrls.push(downloadFileResponse);
  }

  return fileUrls;
};
