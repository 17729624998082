import React, { useState, useMemo, useCallback } from "react";
import { createContext } from "@fluentui/react-context-selector";
import PropTypes from "prop-types";
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { userVerified } from "./src/utils/helper";

export const userContext = createContext();

const config = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_AUTH_DOMAIN,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_APP_ID,
  measurementId: process.env.GATSBY_MEASUREMENT_ID
};

/* eslint-disable react/function-component-definition */
const Provider = ({ children }) => {
  const loadFirebase = async () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    return firebase.app();
  };
  const [fb, setFb] = useState(false);
  const [fbAuth, setFbAuth] = useState([]);
  const [user, setUser] = useState([]);
  const [userLoaded, setUserLoaded] = useState(false);
  const [userVeri, setUserVeri] = useState(null);
  const [fbStorage, setFbStorage] = useState([]);
  const updateUser = useCallback(u => setUser(u), [setUser]);
  const destroyUser = useCallback(() => setUser([]), [setUser]);

  if (fb === false) {
    if (typeof window !== "undefined") {
      loadFirebase(config).then(async app => {
        const auth = getAuth(app);
        const db = getFirestore(app);
        setFb(db);
        setFbAuth(auth);
        setFbStorage(getStorage(app));
        getAnalytics(app);

        onAuthStateChanged(auth, u => {
          if (u !== null) {
            onSnapshot(doc(db, "customers", u.uid), querySnapshot => {
              if (querySnapshot.exists()) {
                const c = querySnapshot.data();
                c.uid = u.uid;
                c.emailVerified = u.emailVerified;
                setUser(c);
                setUserVeri(userVerified(c));
                setUserLoaded(true);
              }
            });
          } else {
            setUserLoaded(true);
          }
        });
      });
    }
  }

  return (
    <userContext.Provider
      value={useMemo(
        () => ({
          updateUser,
          fetchUser: user,
          fetchFirebase: fb,
          fetchFirebaseAuth: fbAuth,
          destroyUser,
          isUserVerified: userVeri,
          fetchStorage: fbStorage,
          userLoaded
        }),
        [
          updateUser,
          user,
          fb,
          fbAuth,
          destroyUser,
          userVeri,
          fbStorage,
          userLoaded
        ]
      )}
    >
      {children}
    </userContext.Provider>
  );
};

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
export default ({ element }) => <Provider>{element}</Provider>;

Provider.propTypes = {
  children: PropTypes.node.isRequired
};
